$font-family-base: 'Open Sans' !default;
$font-family-roboto: 'Roboto', sans-serif !default;
$font-family-montserrat: 'Montserrat', sans-serif !default;
$font-family-sfpro: 'Source Sans 3', sans-serif !default;

/*
  regular - 16px
  tiny - 10px
  xsmall - 12px
  small - 20px
  medium - 24px
  huge - 56px
*/
$sizes: regular, tiny, xsmall, small, medium, huge;
$colors: primary, secondary, preferredStatus, requiresTestingStatus,
  detrimentalStatus, darkGray, gray, lightGray, cta, bgGray, white, disabled;

$grid-breakpoints: (
  xs: 0,       // <576px
  sm: 576px,   // ≥576px
  md: 768px,   // ≥768px
  lg: 992px,   // ≥992px
  xl: 1200px,  // ≥1200px
  xxl: 1400px, // ≥1400px
) !default;
