.infotext {
  display: flex;
  gap: 10px;
  max-width: 350px;
  border: solid 1px #ccc;
  border-radius: 5px;
  background: #eaf0f8;
  box-shadow: 0px 1px 4px rgb(0 0 0 / 15%);
  text-align: left;
  line-height: var(--size--lineheight-small);
  padding: 16px;
}

.action-info-icon, .alert-info-icon {
  cursor: pointer;
}

button.close-icon.mat-button {
  align-self: start;
  min-width: unset;
  padding: 0;
}
