$metrics: (
  // 1px
  spacing--x0-25: 0.0625rem,
  // 2px
  spacing--x05: 0.125rem,
  // 3px
  spacing--x0-75: 0.1875rem,
  // 4px
  spacing--x1: 0.25rem,
  // 5px
  spacing--x1-25: 0.3125rem,
  // 6px
  spacing--x1-5: 0.375rem,
  // 8px
  spacing--x2: 0.5rem,
  // 9px
  spacing--x2-25: 0.5625rem,
  // 10px
  spacing--x2-5: 0.625rem,
  // 11px
  spacing--x2-75: 0.6875rem,
  // 12px
  spacing--x3: 0.75rem,
  // 13px
  spacing--x3-25: 0.8125rem,
  // 14px
  spacing--x3-5: 0.875rem,
  // 15px
  spacing--x3-75: 0.9375rem,
  // 16px
  spacing--x4: 1rem,
  // 16.5px
  spacing--x4-125: 1.03125rem,
  // 17px
  spacing--x4-25: 1.0625rem,
  // 18px
  spacing--x4-5: 1.125rem,
  // 20px
  spacing--x5: 1.25rem,
  // 22px
  spacing--x5-5: 1.375rem,
  // 24px
  spacing--x6: 1.5rem,
  // 26px
  spacing--x6-5: 1.625rem,
  // 28px
  spacing--x7: 1.75rem,
  // 30px
  spacing--x7-5: 1.875rem,
  // 32px
  spacing--x8: 2rem,
  // 33px
  spacing--x8-25: 2.0625rem,
  // 34px
  spacing--x8-5: 2.125rem,
  // 35px
  spacing--x8-75: 2.1875rem,
  // 36px
  spacing--x9: 2.25rem,
  // 38px
  spacing--x9-5: 2.375rem,
  // 40px
  spacing--x10: 2.5rem,
  // 44px
  spacing--x11: 2.75rem,
  // 46px
  spacing--x11-5: 2.875rem,
  // 48px
  spacing--x12: 3rem,
  // 50px
  spacing--x12-5: 3.125rem,
  // 52px
  spacing--x13: 3.25rem,
  // 56px
  spacing--x14: 3.5rem,
  // 58px
  spacing--x14-5: 3.625rem,
  // 60px
  spacing--x15: 3.75rem,
  // 62px
  spacing--x15-5: 3.875rem,
  // 64px
  spacing--x16: 4rem,
  // 66px
  spacing--x16-5: 4.125rem,
  // 68px
  spacing--x17: 4.25rem,
  // 72px
  spacing--x18: 4.5rem,
  // 78px
  spacing--x19-5: 4.875rem,
  // 80px
  spacing--x20: 5rem,
  // 88px
  spacing--x22: 5.5rem,
  // 94px
  spacing--x23-5: 5.875rem,
  // 96px
  spacing--x24: 6rem,
  // 100px
  spacing--x25: 6.25rem,
  // 104px
  spacing--x26: 6.5rem,
  // 108px
  spacing--x27: 6.75rem,
  // 110px
  spacing--x27-5: 6.875rem,
  // 120px
  spacing--x30: 7.5rem,
  // 128px
  spacing--x32: 8rem,
  // 130px
  spacing--x32-5: 8.125rem,
  // 139px
  spacing--x34-75: 8.6875rem,
  // 140px
  spacing--x35: 8.75rem,
  // 144px
  spacing--x36: 9rem,
  // 145px
  spacing--x36-25: 9.0625rem,
  // 146px
  spacing--x36-5: 9.125rem,
  // 150px
  spacing--x37-5: 9.375rem,
  // 160px
  spacing--x40: 10rem,
  // 172px
  spacing--x43: 10.75rem,
  // 182px
  spacing--x45-5: 11.375rem,
  // 184px
  spacing--x46: 11.5rem,
  // 188px
  spacing--x47: 11.75rem,
  // 195px
  spacing--x48-75: 12.19rem,
  // 200px
  spacing--x50: 12.5rem,
  // 208px
  spacing--x52: 13rem,
  // 216px
  spacing--x54: 13.5rem,
  // 218px
  spacing--x54-5: 13.625rem,
  // 222px
  spacing--x55-5: 13.875rem,
  // 248px
  spacing--x62: 15.5rem,
  // 270px
  spacing--x67-5: 16.875rem,
  // 272px
  spacing--x68: 17rem,
  // 284px
  spacing--x71: 17.75rem,
  // 286px
  spacing--x71-5: 17.875rem,
  // 288px
  spacing--x72: 18rem,
  // 296px
  spacing--x74: 18.5rem,
  // 298px
  spacing--x74-5: 18.625rem,
  // 312px
  spacing--x78: 19.5rem,
  // 320px
  spacing--x80: 20rem,
  // 392px
  spacing--x98: 24.5rem,
  // 440px
  spacing--x110: 27.5rem,
  // 472px
  spacing--x118: 29.5rem,
  // 480px
  spacing--x120: 30rem,
  // 496px
  spacing--x124: 31rem,
  // 496px
  spacing--x125-5: 31.375rem,
  // 520px
  spacing--x130: 32.5rem,
  // 560px
  spacing--x140: 35rem,
  // 593px
  spacing--x148-25: 37.0625rem,
  // 600px
  spacing--x150: 37.5rem,
  // 688px
  spacing--x172: 43rem,
  // 750px
  spacing--x187-5: 46.875rem,
  // 1016px
  spacing--x254: 63.5rem
);

@function getSpacing($spacing-name) {
  @return map-get($metrics, $spacing-name);
}

:root {
  // spacing
  --size--spacing-x0-25: #{getSpacing(spacing--x0-25)};
  --size--spacing-x05: #{getSpacing(spacing--x05)};
  --size--spacing-x0-75: #{getSpacing(spacing--x0-75)};
  --size--spacing-x1: #{getSpacing(spacing--x1)};
  --size--spacing-x1-25: #{getSpacing(spacing--x1-25)};
  --size--spacing-x1-5: #{getSpacing(spacing--x1-5)};
  --size--spacing-x2: #{getSpacing(spacing--x2)};
  --size--spacing-x2-25: #{getSpacing(spacing--x2-25)};
  --size--spacing-x2-5: #{getSpacing(spacing--x2-5)};
  --size--spacing-x2-75: #{getSpacing(spacing--x2-75)};
  --size--spacing-x3: #{getSpacing(spacing--x3)};
  --size--spacing-x3-25: #{getSpacing(spacing--x3-25)};
  --size--spacing-x3-5: #{getSpacing(spacing--x3-5)};
  --size--spacing-x3-75: #{getSpacing(spacing--x3-75)};
  --size--spacing-x4: #{getSpacing(spacing--x4)};
  --size--spacing-x4-125: #{getSpacing(spacing--x4-125)};
  --size--spacing-x4-25: #{getSpacing(spacing--x4-25)};
  --size--spacing-x4-5: #{getSpacing(spacing--x4-5)};
  --size--spacing-x5: #{getSpacing(spacing--x5)};
  --size--spacing-x5-5: #{getSpacing(spacing--x5-5)};
  --size--spacing-x6: #{getSpacing(spacing--x6)};
  --size--spacing-x6-5: #{getSpacing(spacing--x6-5)};
  --size--spacing-x7: #{getSpacing(spacing--x7)};
  --size--spacing-x7-5: #{getSpacing(spacing--x7-5)};
  --size--spacing-x8: #{getSpacing(spacing--x8)};
  --size--spacing-x8-25: #{getSpacing(spacing--x8-25)};
  --size--spacing-x8-5: #{getSpacing(spacing--x8-5)};
  --size--spacing-x8-75: #{getSpacing(spacing--x8-75)};
  --size--spacing-x9: #{getSpacing(spacing--x9)};
  --size--spacing-x9-5: #{getSpacing(spacing--x9-5)};
  --size--spacing-x10: #{getSpacing(spacing--x10)};
  --size--spacing-x11: #{getSpacing(spacing--x11)};
  --size--spacing-x11-5: #{getSpacing(spacing--x11-5)};
  --size--spacing-x12: #{getSpacing(spacing--x12)};
  --size--spacing-x12-5: #{getSpacing(spacing--x12-5)};
  --size--spacing-x13: #{getSpacing(spacing--x13)};
  --size--spacing-x14: #{getSpacing(spacing--x14)};
  --size--spacing-x14-5: #{getSpacing(spacing--x14-5)};
  --size--spacing-x15: #{getSpacing(spacing--x15)};
  --size--spacing-x15-5: #{getSpacing(spacing--x15-5)};
  --size--spacing-x16: #{getSpacing(spacing--x16)};
  --size--spacing-x16-5: #{getSpacing(spacing--x16-5)};
  --size--spacing-x17: #{getSpacing(spacing--x17)};
  --size--spacing-x18: #{getSpacing(spacing--x18)};
  --size--spacing-x19-5: #{getSpacing(spacing--x19-5)};
  --size--spacing-x20: #{getSpacing(spacing--x20)};
  --size--spacing-x22: #{getSpacing(spacing--x22)};
  --size--spacing-x23-5: #{getSpacing(spacing--x23-5)};
  --size--spacing-x24: #{getSpacing(spacing--x24)};
  --size--spacing-x25: #{getSpacing(spacing--x25)};
  --size--spacing-x26: #{getSpacing(spacing--x26)};
  --size--spacing-x27: #{getSpacing(spacing--x27)};
  --size--spacing-x27-5: #{getSpacing(spacing--x27-5)};
  --size--spacing-x30: #{getSpacing(spacing--x30)};
  --size--spacing-x32: #{getSpacing(spacing--x32)};
  --size--spacing-x32-5: #{getSpacing(spacing--x32-5)};
  --size--spacing-x34-75: #{getSpacing(spacing--x34-75)};
  --size--spacing-x35: #{getSpacing(spacing--x35)};
  --size--spacing-x36: #{getSpacing(spacing--x36)};
  --size--spacing-x36-25: #{getSpacing(spacing--x36-25)};
  --size--spacing-x36-5: #{getSpacing(spacing--x36-5)};
  --size--spacing-x37-5: #{getSpacing(spacing--x37-5)};
  --size--spacing-x40: #{getSpacing(spacing--x40)};
  --size--spacing-x43: #{getSpacing(spacing--x43)};
  --size--spacing-x45-5: #{getSpacing(spacing--x45-5)};
  --size--spacing-x46: #{getSpacing(spacing--x46)};
  --size--spacing-x47: #{getSpacing(spacing--x47)};
  --size--spacing-x48-75: #{getSpacing(spacing--x48-75)};
  --size--spacing-x50: #{getSpacing(spacing--x50)};
  --size--spacing-x52: #{getSpacing(spacing--x52)};
  --size--spacing-x54: #{getSpacing(spacing--x54)};
  --size--spacing-x54-5: #{getSpacing(spacing--x54-5)};
  --size--spacing-x55-5: #{getSpacing(spacing--x55-5)};
  --size--spacing-x62: #{getSpacing(spacing--x62)};
  --size--spacing-x67-5: #{getSpacing(spacing--x67-5)};
  --size--spacing-x68: #{getSpacing(spacing--x68)};
  --size--spacing-x71: #{getSpacing(spacing--x71)};
  --size--spacing-x71-5: #{getSpacing(spacing--x71-5)};
  --size--spacing-x72: #{getSpacing(spacing--x72)};
  --size--spacing-x74: #{getSpacing(spacing--x74)};
  --size--spacing-x74-5: #{getSpacing(spacing--x74-5)};
  --size--spacing-x78: #{getSpacing(spacing--x78)};
  --size--spacing-x80: #{getSpacing(spacing--x80)};
  --size--spacing-x98: #{getSpacing(spacing--x98)};
  --size--spacing-x110: #{getSpacing(spacing--x110)};
  --size--spacing-x118: #{getSpacing(spacing--x118)};
  --size--spacing-x120: #{getSpacing(spacing--x120)};
  --size--spacing-x124: #{getSpacing(spacing--x124)};
  --size--spacing-x125-5: #{getSpacing(spacing--x125-5)};
  --size--spacing-x130: #{getSpacing(spacing--x130)};
  --size--spacing-x140: #{getSpacing(spacing--x140)};
  --size--spacing-x148-25: #{getSpacing(spacing--x148-25)};
  --size--spacing-x150: #{getSpacing(spacing--x150)};
  --size--spacing-x172: #{getSpacing(spacing--x172)};
  --size--spacing-x187-5: #{getSpacing(spacing--x187-5)};
  --size--spacing-x254: #{getSpacing(spacing--x254)};

  // Spacing
  --size--spacing-xxs: var(--size--spacing-x0-25);
  --size--spacing-xs: var(--size--spacing-x05);
  --size--spacing-s: var(--size--spacing-x1);
  --size--spacing-m: var(--size--spacing-x2);
  --size--spacing-l: var(--size--spacing-x4);
  --size--spacing-l2: var(--size--spacing-x6);
  --size--spacing-xl: var(--size--spacing-x8);
  --size--spacing-xxl: var(--size--spacing-x16);

  // Icon Sizes
  --size--icon-tiny: #{getSpacing(spacing--x2-5)}; // 10px
  --size--icon-xsmall: #{getSpacing(spacing--x3)}; // 12px
  --size--icon-regular: #{getSpacing(spacing--x4)}; // 16px
  --size--icon-small: #{getSpacing(spacing--x5)}; // 20px
  --size--icon-medium: #{getSpacing(spacing--x6)}; // 24px
  --size--icon-huge: #{getSpacing(spacing--x14)}; // 56px

  // Navbar
  --size--navbarHeight: 52.51px;

  // Dropdown
  --size--dropdown-maxHeight: #{getSpacing(spacing--x78)};
  --size--dropdown-optionsItemHeight: #{getSpacing(spacing--x10)};

  // Options button
  --size--optionsButton-maxWidth: #{getSpacing(spacing--x52)};

  // Label
  --size--formField-label-height: 19px;
  --size--formField-label-bottomSpacing: 8px;

  // Logo
  --size--logo-width: 118px;
  --size--logo-height: 37px;

  // Forms
  --size--formField-inputPadding: #{getSpacing(spacing--x3)};
  --size--formField-borderRadius: #{getSpacing(spacing--x1)};
  --size--formField-height: #{getSpacing(spacing--x14)}; // 56px
  --size--formField-bottomSpacing: 16px;
  --size--formField-sidenoteWidth: 210px;

  // Checkbox
  --size--formField-checkbox-height: 42px;

  // Radiolist
  --size--formField-radiolist-height: 42px;

  // Button
  --size--button-height: var(--size--formField-height);
  --size--button-height-small: 30px;
  --size--button-height-big: 42px;
  --size--button-width: 137px;

  // Sidebar
  --size--community-sidebarWidth: var(--size--spacing-x71);

  // Community
  --size--community-topbarHeight: 111px;

  // Scroll
  --size--scroll-padding: #{getSpacing(spacing--x20)};

  // Layout
  --size--siteFooterButtonSpacing: #{getSpacing(spacing--x11-5)};
}
