@use '../settings/variables' as vars;

*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: currentColor;
}

button {
  background-color: transparent;
  background-image: none;
}

ol,
ul {
  list-style: none;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;

  &:not(.link) {
    text-decoration: none;
  }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: vars.$font-family-base;
  font-style: normal;
}
