$text-accent-color: #2f6f7a;
$text-primary-color: #4a4a4a;
$text-secondary: #2f6f7a;

.text-primary {
  color: $text-primary-color;
}

.text-secondary {
  color: $text-secondary;
}

.bg-white {
  background: white;
}
