.h-center[mat-button] .mat-button-wrapper {
  display: flex;
  align-items: center;
}

.action-button .mat-button-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.expand-button .mat-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
