@forward 'reset';

@forward 'typography';

@forward 'forms';

@forward 'utils';

@use '../settings/variables' as vars;

$headings: 1, 2;

@each $h in $headings {
  h#{$h} {
    font-size: var(--size--font-heading-#{$h});
    line-height: var(--size--lineheight-heading-#{$h});
    letter-spacing: var(--size--letterspacing-heading-#{$h});
    font-weight: var(--size--fontweight-heading-#{$h});
    text-align: left;
    color: var(--color--primary);
    margin: 0;
    padding: 0;
  }
}

h1 {
  font-family: #{vars.$font-family-montserrat};
}

h6 {
  font-family: #{vars.$font-family-roboto};
}

$bodies: 2;

@each $b in $bodies {
  p.body-#{$b} {
    font-family: #{vars.$font-family-base};
    font-size: var(--size--font-body-#{$b});
    line-height: var(--size--lineheight-body-#{$b});
    letter-spacing: var(--size--letterspacing-body-#{$b});
    font-weight: var(--size--fontweight-body-#{$b});
    text-align: left;
    color: var(--color--primary);
  }
}

.link {
  &.cta {
    color: var(--color--secondary);
  }
}
