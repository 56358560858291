@use 'normalize.css' as normalize;
@use './settings';
@use './abstracts';
@use './components';

html {
  @include components.scrollbar;
}

@include components.appInitLoadingLayer;

a.trp-contact-link {
  text-decoration: underline;
}
