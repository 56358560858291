@use 'sass:list';

$supported-directions: 'y', 'x', 'both';

@mixin scrollbar($direction: 'y') {
  @if not list.index($supported-directions, $direction) {
    @error "Value for direction must be one of the following; y, x, both";
  }

  & {
    @if $direction == 'y' {
      overflow-y: auto;
      overflow-x: hidden;
    }

    @if $direction == 'x' {
      overflow-x: auto;
      overflow-y: hidden;
    }

    @if $direction == 'both' {
      overflow: auto;
    }
  }

  &::-webkit-scrollbar-track {
    border-radius: var(--size--spacing-x2-25);
    background-color: var(--color--scrollbar-track);
    padding-right: var(--size--spacing-x2-5);
  }

  &::-webkit-scrollbar {
    width: var(--size--spacing-x2-25);
    background-color: transparent;
    cursor: default;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: var(--size--spacing-x2-25);
    background-color: var(--color--scrollbar-thumb);
  }
}
