@for $i from 0 through 36 {
  @if ($i) % 2 == 0 {
    .pl-#{$i} {
      padding-left: #{$i}px;
    }

    .pr-#{$i} {
      padding-right: #{$i}px;
    }

    .pt-#{$i} {
      padding-top: #{$i}px;
    }

    .pb-#{$i} {
      padding-bottom: #{$i}px;
    }

    .px-#{$i} {
      padding-left: #{$i}px;
      padding-right: #{$i}px;
    }

    .py-#{$i} {
      padding-top: #{$i}px;
      padding-bottom: #{$i}px;
    }

    .p-#{$i} {
      padding-left: #{$i}px;
      padding-right: #{$i}px;
      padding-top: #{$i}px;
      padding-bottom: #{$i}px;
    }
  }
}
