.map-tooltip {
  font-size: var(--size--font-body-small) !important;
  background: var(--color--white) !important;
  color: var(--emerald-70) !important;
  box-shadow: 0px 1px 4px rgb(0 0 0 / 15%) !important;
}

.info-overlay, .editing-disabled-tooltip {
  font-size: var(--size--font-body-small) !important;
  background: var(--color--white) !important;
  color: black !important;
  box-shadow: 0px 1px 4px rgb(0 0 0 / 15%) !important;
}
