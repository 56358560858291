
.info-overlay {
  font-size: var(--size--font-body-small) !important;
  background: var(--color--white) !important;
  color: black !important;
  padding: 1em;
  width: 500px;
  border: 1px solid var(--color--shadow) !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}
