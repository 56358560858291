:root {
  --size--font-base: 1rem;

  // Letter spacing
  --size--letter-spacing-tight: 0.00938rem; // 0.15px
  --size--letter-spacing-close: 0.0288rem; // 0.46px
  --size--letter-spacing-wide: 0.0625rem; // 1px

  // Font weight
  --size--font-weight-thin: 300;
  --size--font-weight-regular: 400;
  --size--font-weight-semi-bold: 500;
  --size--font-weight-bold: 600;
  --size--font-weight-bolder: 700;

  // Display
  --size--font-display-1: 14.552rem;
  --size--fontweight-display-1: var(--size--font-weight-bold);
  --size--lineheight-display-1: 14.552rem;
  --size--letterspacing-display-1: 0rem;

  // Subhead
  --size--font-subhead: 0.875rem;
  --size-lineheight-subhead: 2.325rem;
  --size--letterspacing-subhead: 1px;
  --size-fontweight-subhead: var(--size--font-weight-regular);

  // Heading
  --size--font-heading-1: 1.75rem;
  --size--fontweight-heading-1: var(--size--font-weight-bold);
  --size--lineheight-heading-1: 2.125rem;
  --size--letterspacing-heading-1: 0rem;
  --size--letterspacing-title: 0.025rem;

  --size--font-heading-2: 1.25rem;
  --size--fontweight-heading-2: var(--size--font-weight-semi-bold);
  --size--lineheight-heading-2: 2rem;
  --size--letterspacing-heading-2: 0.00938rem;

  --size--font-heading-3: 1.125rem;
  --size--fontweight-heading-3: var(--size--font-weight-regular);
  --size--lineheight-heading-3: 1.625rem;
  --size--letterspacing-heading-3: 0.00938rem;

  --size--font-heading-5: 1rem;
  --size--fontweight-heading-5: var(--size--font-weight-bolder);
  --size--lineheight-heading-5: 1.625rem;
  --size--letterspacing-heading-5: 0.00938rem;

  // Card Heading
  --size--font-heading-card: 1.25rem;
  --size--fontweight-heading-card: var(--size--font-weight-semi-bold);
  --size--lineheight-heading-card: 2rem;
  --size--letterspacing-heading-card: 0.0094rem;

  // Body
  --size--font-body-large: 1.25rem; // 20px
  --size--lineheight-body-large: 2rem; // 32px

  --size--font-body-regular: 1rem; // 16px
  --size--lineheight-body-regular: 1.5rem; // 24px

  --size--font-body-small: 0.875rem; // 14px
  --size--lineheight-body-small: 1.5rem; // 24px

  --size--font-body-xs: 0.75rem; // 12px
  --size--lineheight-body-xs: 0.875rem; // 24px


  --size--font-body-2: 1rem;
  --size--fontweight-body-2: var(--size--font-weight-regular);
  --size--lineheight-body-2: 1.5rem;
  --size--letterspacing-body-2: 0.00938rem;

  --size--font-body-3: var(--size--font-body-small);
  --size--fontweight-body-3: var(--size--font-weight-regular);
  --size--lineheight-body-3: 1.375rem;
  --size--letterspacing-body-3: 0.00625rem;

  // Line height
  --size--lineheight-large: 2.3125rem; // 37px
  --size--lineheight-medium: 1.625rem; // 26px
  --size--lineheight-regular: 1.5rem; // 24px
  --size--lineheight-small: 1.375rem; // 22px
  --size--lineheight-tiny: 1.25rem; // 20px
  --size--lineheight-xs: 0.875rem; // 14px
}
