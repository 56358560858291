//  Color Palette

$palette: (
  // Brand Colors
  color--primary: #4a4a4a,
  color--secondary: #2f6f7a,
  color--tertiary: #003151,
  color--deepOcean: #244C5A,
  color--preferredStatus: #55BE47,
  color--requiresTestingStatus: #FFB547,
  color--detrimentalStatus: #D86018,
  color--rendersNonRecyclableStatus: #E31B0C,
  color--darkGray: #4a4a4a,
  color--mediumGray: #6B7280,
  color--mediumGray2: #716E6A,
  color--gray: #00000099,
  color--lightGray: #0000000a,
  color--lightGrayBg: #F0F1F9,
  color--lightGrayBgHover: #6B7280,
  color--tableLightGray: #eeeeee,
  color--shadow: #e0e0e0,
  color--cta: #2f6f7a,
  color--ctaLight: #CBDBDE,
  color--bgGray: #fafafa,
  color--white: #ffffff,
  color--transparent: transparent,
  color--disabled: #00000061,
  color--highlight: #2f6f7a14,
  color--success: #55be47,
  color--warning: #ff9800,
  color--error: #dc1e1e,
  color--danger: #E31B0C,
  color--info: #0EA5E9,
  color--black: #000000,
  color--tangerine: #F98E2B,

  // Scrollbar
  color--scrollbarTrack: #00000014,
  color--scrollbarThumb: #00000042,

  // Popup
  color--popupFollowupBg: #FBFBFF,

  // Recyclability Wheel
  color--recyclabilityPreferred: #5CBA4A,
  color--recyclabilityRequiresTesting: #FF9800,
  color--recyclabilityDetrimental: #DC1E1E,
  color--recyclabilityRendersNonRecyclable: #DC1E1E,
  color--recyclabilityNotYetAvailable: #414042,
  color--recyclabilityNone: #414042,
  color--recyclabilityStageIcon: #414042,

  // Snackbar
  color--snackbarWarningBackground: #f8e1d3,

);

@function c($color-name) {
  @return map-get($palette, $color-name);
}

:root {
  --color--primary: #{c(color--primary)};
  --color--secondary: #{c(color--secondary)};
  --color--tertiary: #{c(color--tertiary)};
  --color--deepOcean: #{c(color--deepOcean)};
  --color--success: #{c(color--success)};
  --color--warning: #{c(color--warning)};
  --color--error: #{c(color--error)};
  --color--danger: #{c(color--danger)};
  --color--info: #{c(color--info)};
  --color--black: #{c(color--black)};
  --color--white: #{c(color--white)};
  --color--lightGray: #{c(color--lightGray)};
  --color--tableLightGray: #{c(color--tableLightGray)};
  --color--mediumGray: #{c(color--mediumGray)};
  --color--shadow: #{c(color--shadow)};
  --color--bgGray: #{c(color--bgGray)};

  --color--black: #{c(color--black)};
  --color--tangerine: #{c(color--tangerine)};

  --color--cta: #{c(color--cta)};
  --color--ctaLight: #{c(color--ctaLight)};

  --color--highlight: #{c(color--highlight)};

  --color--lightGrayBg: #{c(color--lightGrayBg)};
  --color--lightGrayBgHover: #{c(color--lightGrayBgHover)};

  // Divider
  --color--divider-900: #000000;
  --color--divider-500: #c4c4c4; // => #c4c4c4;
  --color--divider-400: #D9D9D9; // => #D9D9D9;
  --color--divider-100: rgba(0, 0, 0, .08);

  // Background
  --color--mainContent-background: #{c(color--bgGray)};

  // Text Colors
  --color--text: #{c(color--darkGray)};
  --color--text-disabled: #{c(color--disabled)};

  // Navigation
  --color--navTextPrimary-default: #{c(color--primary)};
  --color--navTextPrimary-active: #{c(color--cta)};
  --color--navTextPrimary-disabled: #{c(color--disabled)};
  --color--navTextPrimary-hover: #{c(color--primary)};

  --color--navTextSecondary-default: #{c(color--primary)};
  --color--navTextSecondary-active: #{c(color--cta)};
  --color--navTextSecondary-disabled: #{c(color--disabled)};
  --color--navTextSecondary-hover: #{c(color--primary)};

  --color--navTextTertiary-default: #{c(color--disabled)};
  --color--navTextTertiary-active: #{c(color--disabled)};
  --color--navTextTertiary-disabled: #{c(color--disabled)};
  --color--navTextTertiary-hover: #{c(color--disabled)};

  // Icon Colors
  --color--icon-primary: #{c(color--primary)};
  --color--icon-secondary: #{c(color--secondary)};
  --color--icon-preferredStatus: #{c(color--preferredStatus)};
  --color--icon-requiresTestingStatus: #{c(color--requiresTestingStatus)};
  --color--icon-detrimentalStatus: #{c(color--detrimentalStatus)};
  --color--icon-rendersNonRecyclableStatus: #{c(color--rendersNonRecyclableStatus)};
  --color--icon-darkGray: #{c(color--darkGray)};
  --color--icon-gray: #{c(color--gray)};
  --color--icon-lightGray: #{c(color--lightGray)};
  --color--icon-cta: #{c(color--cta)};
  --color--icon-bgGray: #{c(color--bgGray)};
  --color--icon-white: #{c(color--white)};
  --color--icon-disabled: #{c(color--disabled)};

  // Button
  --color--buttonSecondary-borderDefault: #{c(color--secondary)};
  --color--buttonSecondary-borderHover: #2f6f7a80;
  --color--buttonSecondary-borderDisabled: #0000001f;

  --color--buttonPrimary-bgDefault: #{c(color--secondary)};
  --color--buttonPrimary-bgHover: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3)
    ),
    var(--color--secondary);
  --color--buttonPrimary-bgDisabled: rgba(0, 0, 0, 0.12);
  --color--buttonPrimary-textDefault: #{c(color--white)};
  --color--buttonPrimary-textHover: #{c(color--white)};
  --color--buttonPrimary-textDisabled: rgba(0, 0, 0, 0.26);

  --color--buttonSecondary-bgDefault: #{c(color--white)};
  --color--buttonSecondary-bgHover: rgba(47, 111, 122, 0.08);
  --color--buttonSecondary-bgDisabled: #{c(color--white)};
  --color--buttonSecondary-textDefault: #{c(color--secondary)};
  --color--buttonSecondary-textHover: #{c(color--secondary)};
  --color--buttonSecondary-textDisabled: rgba(0, 0, 0, 0.26);

  --color--buttonTertiary-bgDefault: #{c(color--transparent)};
  --color--buttonTertiary-bgHover: rgba(47, 111, 122, 0.08);
  --color--buttonTertiary-bgDisabled: #{c(color--transparent)};
  --color--buttonTertiary-textDefault: #{c(color--secondary)};
  --color--buttonTertiary-textHover: #{c(color--secondary)};
  --color--buttonTertiary-textDisabled: rgba(0, 0, 0, 0.26);

  // Forms
  --color--formField-background: #{c(color--white)};
  --color--formField-border: rgba(0, 0, 0, 0.23);
  --color--formField-placeholderDefault: rgba(0, 0, 0, 0.6);
  --color--formField-placeholderReadonly: rgba(0, 0, 0, 0.23);
  --color--formField-text: #{c(color--primary)};
  --color--formField-captionText: #{c(color--primary)};

  // Label
  --color--formField-label-text: rgba(0, 0, 0, 0.87);

  // Dropdown
  --color--dropdown-optionsItem-bgHover: #{c(color--lightGray)};
  --color--dropdown-optionsItem-divider: #c4c4c4;

  // Scrollbar
  --color--scrollbar-track: #{c(color--scrollbarTrack)};
  --color--scrollbar-thumb: #{c(color--scrollbarThumb)};

  // Dialog
  --color--dialog-actionSectionDivider: #434444;
  --color--dialog-actionSectionBackground: #f5f5f5;

  // Popup
  --color--popupContent-background: var(--color--lightGrayBg);
  --color--popupFollowup-background: #{c(color--popupFollowupBg)};

  // Recyclability Wheel
  --color--recyclability-preferred: #{c(color--recyclabilityPreferred)};
  --color--recyclability-requiresTesting: #{c(color--recyclabilityRequiresTesting)};
  --color--recyclability-detrimental: #{c(color--recyclabilityDetrimental)};
  --color--recyclability-rendersNonRecyclable: #{c(color--recyclabilityRendersNonRecyclable)};
  --color--recyclability-notYetAvailable: #{c(color--recyclabilityNotYetAvailable)};
  --color--recyclability-none: #{c(color--recyclabilityNone)};
  --color--recyclability-stageIcon: #{c(color--recyclabilityStageIcon)};


  --color--recyclability-aprRecognized: #{c(color--recyclabilityPreferred)};
  --color--recyclability-easierToRecycle: #{c(color--recyclabilityPreferred)};
  --color--recyclability-acceptable: #{c(color--recyclabilityRequiresTesting)};
  --color--recyclability-maybeRecyclingChallenge: #{c(color--recyclabilityRequiresTesting)};

  // Recyclability status
  --color--preferredStatus: #{c(color--preferredStatus)};
  --color--requiresTestingStatus: #{c(color--requiresTestingStatus)};
  --color--detrimentalStatus: #{c(color--detrimentalStatus)};
  --color--rendersNonRecyclableStatus: #{c(color--rendersNonRecyclableStatus)};

  // Help Text
  --color--help-text: #{c(color--mediumGray)};

  // Snackbar
  --color--snackbar-successText: #{c(color--white)};
  --color--snackbar-successBackground: #{c(color--cta)};

  --color--snackbar-errorText: #{c(color--white)};
  --color--snackbar-errorBackground: #{c(color--error)};

  --color--snackbar-warningText: #{c(color--primary)};
  --color--snackbar-warningBackground: #{c(color--snackbarWarningBackground)};

  --color--snackbar-infoText: #{c(color--white)};
  --color--snackbar-infoBackground: #{c(color--info)};

  // Shadows
  --shadow--box-small: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
  --shadow--box-medium: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12);
}
