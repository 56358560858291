@for $i from 0 through 36 {
  @if ($i) % 2 == 0 {
    .ml-#{$i} {
      margin-left: #{$i}px;
    }

    .mr-#{$i} {
      margin-right: #{$i}px;
    }

    .mt-#{$i} {
      margin-top: #{$i}px;
    }

    .mb-#{$i} {
      margin-bottom: #{$i}px;
    }

    .mx-#{$i} {
      margin-left: #{$i}px;
      margin-right: #{$i}px;
    }

    .my-#{$i} {
      margin-top: #{$i}px;
      margin-bottom: #{$i}px;
    }
  }
}

.ml-auto{
  margin-left: auto;
}

.mr-auto{
  margin-right: auto;
}