@use 'sass:list';
@use '../settings/variables' as vars;



// Body
@mixin typo--body-large {
  font-family: vars.$font-family-base;
  font-weight: var(--size--font-weight-semi-bold);
  font-size: var(--size--font-body-large);
  line-height: var(--size--lineheight-body-large);
  letter-spacing: var(--size--letter-spacing-tight);
  color: var(--color--primary);
}

@mixin typo--body-regular {
  font-family: vars.$font-family-base;
  font-size: var(--size--font-body-regular);
  line-height: var(--size--lineheight-body-regular);
  letter-spacing: var(--size--letter-spacing-tight);
  font-weight: var(--size--font-weight-regular);
  color: var(--color--primary);
}

@mixin typo--body-small {
  font-family: vars.$font-family-base;
  font-size: var(--size--font-body-small);
  line-height: var(--size--lineheight-body-regular);
  letter-spacing: var(--size--letter-spacing-tight);
  font-weight: var(--size--font-weight-regular);
  color: var(--color--primary);
}

@mixin typo--body-xsmall {
  font-family: vars.$font-family-base;
  font-size: var(--size--font-body-xs);
  line-height: var(--size--lineheight-body-xs);
  letter-spacing: var(--size--letter-spacing-close);
  font-weight: var(--size--font-weight-regular);
  color: var(--color--primary);
}

@mixin typo--body-2 {
  font-family: vars.$font-family-base;
  font-size: var(--size--font-body-2);
  line-height: var(--size--lineheight-body-2);
  letter-spacing: var(--size--letterspacing-body-2);
  font-weight: var(--size--fontweight-body-2);
  text-align: left;
  color: var(--color--primary);
}

@mixin typo--body-3 {
  font-family: vars.$font-family-base;
  font-size: var(--size--font-body-3);
  line-height: var(--size--lineheight-body-3);
  letter-spacing: var(--size--letterspacing-body-3);
  font-weight: var(--size--fontweight-body-3);
  text-align: left;
  color: var(--color--primary);
}

// Navigation

@mixin header {
  font-family: vars.$font-family-roboto;
  font-size: var(--size--font-body-xs);
  font-weight: var(--size--fontweight-body-2);
  line-height: var(--size--lineheight-xs);
  letter-spacing: var(--size--letterspacing-title);
}

@mixin typo--primaryNavLink {
  font-family: vars.$font-family-base;
  font-style: normal;
  font-weight: var(--size--font-weight-bolder);
  font-size: var(--size--font-body-regular);
  line-height: var(--size--lineheight-regular);
  letter-spacing: var(--size--letter-spacing-tight);
  text-transform: none;
}

@mixin typo--secondaryNavLink {
  font-family: vars.$font-family-base;
  font-style: normal;
  font-weight: var(--size--font-weight-semi-bold);
  font-size: var(--size--font-body-regular);
  line-height: var(--size--lineheight-regular);
  letter-spacing: var(--size--letter-spacing-tight);
  text-transform: none;
}

@mixin typo--tertiaryNavLink {
  font-family: vars.$font-family-base;
  font-style: normal;
  font-weight: var(--size--font-weight-regular);
  font-size: var(--size--font-body-small);
  line-height: var(--size--lineheight-large);
  letter-spacing: var(--size--letter-spacing-wide);
  text-transform: uppercase;
}

// Button
@mixin typo--button {
  font-style: normal;
  font-weight: var(--size--font-weight-bolder);
  font-size: var(--size--font-body-regular);
  line-height: var(--size--lineheight-medium);
  letter-spacing: var(--size--letter-spacing-close);
}

// Field Label
@mixin typo--fieldLabel {
  font-style: normal;
  font-weight: var(--size--font-weight-regular);
  font-size: var(--size--font-body-regular);
  line-height: var(--size--lineheight-body-regular);
  letter-spacing: var(--size--letter-spacing-tight);
}

// Field Label
@mixin typo--fieldInput {
  font-weight: var(--size--font-weight-regular);
  font-size: var(--size--font-body-regular);
  line-height: var(--size--lineheight-body-regular);
}

// Field Help Text
@mixin typo--fieldHelpText {
  font-weight: var(--size--font-weight-regular);
  font-size: var(--size--font-body-small);
  line-height: var(--size--lineheight-tiny);
}

@mixin text-unselectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
    Introduced in Internet Explorer 10.
    See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
  */
  -ms-user-select: none;
  user-select: none;
}

/*
  Requires inline-block or block for proper styling
*/
$display-values: 'inline-block', 'block';
@mixin text-ellipsis($display) {
  @if not list.index($display-values, $display) {
    @error "Display value must be; inline-block or block";
  }

  display: #{$display};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// Display
@mixin typo--display-1 {
  font-family: vars.$font-family-montserrat;
  font-size: var(--size--font-display-1);
  line-height: var(--size--lineheight-display-1);
  letter-spacing: var(--size--letterspacing-display-1);
  font-weight: var(--size--fontweight-display-1);
  text-align: left;
  color: var(--color--primary);
}

@mixin typo-subhead {
  // font-family: Open sans (by default)
  font-size: var(--size--font-subhead);
  line-height: var(--size--lineheight-subhead);
  letter-spacing: var(--size--letterspacing-subhead);
  font-weight: var(--size--fontweight-subhead);
  color: var(--color--mediumGray2);
  text-transform: uppercase;
}

// Heading
@mixin typo--heading-1 {
  font-family: vars.$font-family-montserrat;
  font-size: var(--size--font-heading-1);
  line-height: var(--size--lineheight-heading-1);
  letter-spacing: var(--size--letterspacing-heading-1);
  font-weight: var(--size--fontweight-heading-1);
  text-align: left;
  color: var(--color--primary);
}

@mixin typo--heading-1-roboto {
  font-family: vars.$font-family-roboto;
  font-size: 20px;
  line-height: var(--size--lineheight-heading-1);
  letter-spacing: var(--size--letterspacing-heading-1);
  font-weight: var(--size--fontweight-heading-1);
  color: var(--color--primary);
}

@mixin typo--heading-2 {
  font-family: vars.$font-family-roboto;
  font-size: var(--size--font-heading-2);
  line-height: var(--size--lineheight-heading-2);
  letter-spacing: var(--size--letterspacing-heading-2);
  font-weight: var(--size--fontweight-heading-2);
  text-align: left;
  color: var(--color--primary);
}

@mixin typo--heading-3 {
  font-family: vars.$font-family-roboto;
  font-size: var(--size--font-heading-3);
  line-height: var(--size--lineheight-heading-3);
  letter-spacing: var(--size--letterspacing-heading-3);
  font-weight: var(--size--fontweight-heading-3);
  text-align: left;
  color: var(--color--primary);
}


$supported-fonts: 'base', 'roboto', 'montserrat', 'sfpro';

@mixin typo--heading-5($font: 'roboto') {
  @if not list.index($supported-fonts, $font) {
    @error "Value for font must be one of the following; base, roboto, montserrat, sfpro";
  }

  @if $font == 'base' {
    font-family: vars.$font-family-base;
  }

  @if $font == 'roboto' {
    font-family: vars.$font-family-roboto;
  }

  @if $font == 'montserrat' {
    font-family: vars.$font-family-montserrat;
  }

  @if $font == 'sfpro' {
    font-family: vars.$font-family-sfpro;
  }

  font-size: var(--size--font-heading-5);
  line-height: var(--size--lineheight-heading-5);
  letter-spacing: var(--size--letterspacing-heading-5);
  font-weight: var(--size--fontweight-heading-5);
  text-align: left;
  color: var(--color--primary);
}

@mixin card-heading {
  font-family: vars.$font-family-roboto;
  font-size: var(--size--font-heading-card);
  font-weight: var(--size--fontweight-heading-card);
  line-height: var(--size--lineheight-heading-card);
  letter-spacing: var(--size--letterspacing-heading-card);
  text-align: left;
  color: var(--color--primary);
}

@mixin page-heading {
  @include typo--heading-1;

  margin-bottom: var(--size--spacing-x3);
}

@mixin page-description {
  @include typo--body-2;
  display: flex;
  flex-direction: column;
  gap: var(--size--spacing-l);
}

@mixin more-link {
  @include typo--body-3;
  text-decoration: underline;
}
