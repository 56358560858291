@use 'libs/community/shared/assets/src/lib/styles/main.scss';

@use '@angular/material' as mat;

@use './community-theme.scss' as *;


:root{

  --grey-10:  #{$grey-10};
  --grey-20:  #{$grey-20};
  --grey-30:  #{$grey-30};
  --grey-40:  #{$grey-40};
  --grey-50:  #{$grey-50};
  --grey-60:  #{$grey-60};
  --grey-70:  #{$grey-70};
  --grey-80:  #{$grey-80};
  --grey-90:  #{$grey-90};
  --grey-100: #{$grey-10};
  --grey: var(--grey-90);

  --ocean-100: #{$ocean-100};
  --ocean-90:  #{$ocean-90};
  --ocean-80:  #{$ocean-80};
  --ocean-70:  #{$ocean-70};
  --ocean-60:  #{$ocean-60};
  --ocean-50:  #{$ocean-50};
  --ocean-40:  #{$ocean-40};
  --ocean-30:  #{$ocean-30};
  --ocean-20:  #{$ocean-20};
  --ocean-10:  #{$ocean-10};
  --ocean-5:   #{$ocean-5};
  --ocean: var(--ocean-80);

  --forest-100:#{$forest-100};
  --forest-90: #{$forest-90};
  --forest-80: #{$forest-80};
  --forest-70: #{$forest-70};
  --forest-60: #{$forest-60};
  --forest-50: #{$forest-50};
  --forest-40: #{$forest-40};
  --forest-30: #{$forest-30};
  --forest-20: #{$forest-20};
  --forest-10: #{$forest-10};
  --forest: var(--forest-80);

  --emerald-100:#{$emerald-100};
  --emerald-90: #{$emerald-90};
  --emerald-80: #{$emerald-80};
  --emerald-70: #{$emerald-70};
  --emerald-60: #{$emerald-60};
  --emerald-50: #{$emerald-50};
  --emerald-40: #{$emerald-40};
  --emerald-30: #{$emerald-30};
  --emerald-20: #{$emerald-20};
  --emerald-10: #{$emerald-10};
  --emerald: var(--emerald-70);

  --mint-100: #{$mint-100};
  --mint-90:  #{$mint-90};
  --mint-80:  #{$mint-80};
  --mint-70:  #{$mint-70};
  --mint-60:  #{$mint-60};
  --mint-50:  #{$mint-50};
  --mint-40:  #{$mint-40};
  --mint-30:  #{$mint-30};
  --mint-20:  #{$mint-20};
  --mint-10:  #{$mint-10};
  --mint: var(--mint-70);

  --lime-100: #{$lime-100};
  --lime-90:  #{$lime-90};
  --lime-80:  #{$lime-80};
  --lime-70:  #{$lime-70};
  --lime-60:  #{$lime-60};
  --lime-50:  #{$lime-50};
  --lime-40:  #{$lime-40};
  --lime-30:  #{$lime-30};
  --lime-20:  #{$lime-20};
  --lime-10:  #{$lime-10};
  --lime: var(--lime-70);

  --golden-100: #{$golden-100};
  --golden-90:  #{$golden-90};
  --golden-80:  #{$golden-80};
  --golden-70:  #{$golden-70};
  --golden-60:  #{$golden-60};
  --golden-50:  #{$golden-50};
  --golden-40:  #{$golden-40};
  --golden-30:  #{$golden-30};
  --golden-20:  #{$golden-20};
  --golden-10:  #{$golden-10};
  --golden: var(--golden-70);

  --tangerine-100:#{$tangerine-100};
  --tangerine-90: #{$tangerine-90};
  --tangerine-80: #{$tangerine-80};
  --tangerine-70: #{$tangerine-70};
  --tangerine-60: #{$tangerine-60};
  --tangerine-50: #{$tangerine-50};
  --tangerine-40: #{$tangerine-40};
  --tangerine-30: #{$tangerine-30};
  --tangerine-20: #{$tangerine-20};
  --tangerine-10: #{$tangerine-10};
  --tangerine: var(--tangerine-70);
  --accent-color: var(--emerald-70);

  --not-yet-verified-status-color: var(--tangerine-60);
  --verified-status-color: var(--emerald-70);
  --reverification-needed-status-color: #E31B0C;
  --partially-verified-status-color: var(--golden-50);
}


.accent{
  color: var(--accent-color);
}

.with-block{
  display: flex;
  align-items: center;
}

.with-block {
  mat-icon {
    color: var(--emerald);
    stroke: var(--emerald);
    margin-right: 12px;
    height: 24px;
    width: 24px;
  }
}

h2{
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 160%;
  /* or 38px */
  letter-spacing: 0.15px;
}

trp-banner {
  [trpBannerAction] {
    background: transparent;
  }

  [trpBannerAction][disabled] {
    opacity: 0.5;
  }

  &.neutral {
    [trpBannerAction][mat-button][mat-stroked-button], mat-spinner {
      color: white;
      border-color: white;
    }
  }

  &.error {
    [trpBannerAction][mat-button][mat-stroked-button], mat-spinner {
      color: white;
      border-color: white;
    }
  }

  &.warning {
    [trpBannerAction][mat-button][mat-stroked-button], mat-spinner {
      color: #4A4A4A;
      border-color: #4A4A4A;
    }
  }

  &.info {
    [trpBannerAction][mat-button][mat-stroked-button], mat-spinner {
      color: white;
      border-color: white;
    }
  }
}


.review-data-dialog-panel {
  .mat-dialog-container {
    background: #f9f9f9;
    padding: 0;
  }
}

.mat-menu-panel {
  // MatMenu doesn't have a panelClass, therefore we use that !important. Sorry for that
  max-width: 400px !important;
}

button {
  trp-spinner {
    display: inline-block;
  }
}

button[disabled] {
  trp-spinner {
    --spinner-color: rgba(0, 0, 0, 0.26);
    margin-right: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

mat-icon[data-mat-icon-name="Edit"][data-mat-icon-namespace="custom-solid"] {
  margin-right: 8px;
}

.html {
  ol {
    list-style: decimal outside;
    list-style-type: decimal;
    margin-left: 1rem;
  }

  li {
    padding-left: 0.2rem;
  }
}

trp-community-update-form .mat-form-field-infix {
  border-top-width: 0.2em !important;
}

trp-community-update-form .mat-select-arrow-wrapper {
  padding-top: 0.5em;
}


.panel-class-dialog {
  .mat-dialog-title.title, .description {
    font-family: 'Source Sans Pro' !important;
  }
}


.link-without-href {
  text-decoration: underline;
  cursor: pointer;
}

.link-inline {
  display: inline;
}


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance:textfield;
}
