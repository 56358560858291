@use '@trp/styles/abstracts/typography' as typo;

:host {
  --notice-error-icon-color: #F44336;
  --notice-error-background-color: #FDECEA;
  --notice-error-text-color: #621B12;
}

@mixin notice-info {
  background-color: var(--color--ctaLight);
  padding: var(--size--spacing-x4);
  box-shadow: inset 0px -1px 0px var(--color--shadow);

  @include typo.typo--body-2;

  display: flex;
  align-items: center;
  gap: .5rem;
  position: relative;
  padding-left: var(--size--spacing-x12) !important;
  font-size: 17px;
  font-family: 'Source Sans Pro';

  & .icon, & mat-spinner {
    width: 30px;
    margin-left: calc(-1 * (var(--size--spacing-x12) / 2));
  }
}

trp-notice p, .notice-message {
  margin: 0;
}
