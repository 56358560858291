.section {
  &.preferred span.status, &.easierToRecycle span.status {
    color: var(--color--success);
  }
  &.detrimental span.status {
    color: var(--color--error);
  }
  &.rendersNonRecyclable span.status {
    color: var(--color--error);
  }
  &.requiresTesting span.status, &.acceptable span.status {
    color: var(--color--warning);
  }
  &.maybeRecyclingChallenge span.status {
    color: var(--color--requiresTestingStatus);
  }
}

.recyclability-status--none {
  color: var(--color--recyclability-none);
}

.recyclability-status--preferred {
  color: var(--color--recyclability-preferred);
}

.recyclability-status--aprRecognized {
  color: var(--color--recyclability-aprRecognized);
}

.recyclability-status--detrimental {
  color: var(--color--recyclability-detrimental);
}

.recyclability-status--rendersNonRecyclable {
  color: var(--color--recyclability-rendersNonRecyclable);
}

.recyclability-status--requiresTesting {
  color: var(--color--recyclability-requiresTesting);
}

.recyclability-status--easierToRecycle {
  color: var(--color--recyclability-easierToRecycle);
}

.recyclability-status--acceptable {
  color: var(--color--recyclability-acceptable);
}

.recyclability-status--maybeRecyclingChallenge {
  color: var(--color--recyclability-maybeRecyclingChallenge);
}

.recyclability-status--moreDataNeeded {
  color: var(--color--recyclability-requiresTesting);
}

.recyclability-status--notYetAvailable {
  color: var(--color--recyclability-notYetAvailable);
}
