:root {
  // Duration
  --duration--hover: 400ms;
  --duration--slow: 1000ms;
  --duration--fast: 200ms;
  --duration--rapid: 50ms;

  // Ease
  --ease-out-back: cubic-bezier(0.34, 1.56, 0.64, 1);
  --ease-in-out-back: cubic-bezier(0.68, -0.6, 0.32, 1.6);
  --ease-in-back: cubic-bezier(0.36, 0, 0.66, -0.56);
}
