@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');

* {
  font-family: 'Source Sans Pro', sans-serif;
}

body {
  background: #fafafa;
}

.notion-content {
  &>p:first-child {
    margin-top: 0;
  }
  &>p:last-child {
    margin-bottom: 0;
  }
}
