.flex {
  display: flex;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: end;
}

.self-start {
  align-self: start;
}

.flex-col {
  flex-direction: column;
}
