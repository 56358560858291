
@mixin appInitLoadingLayer() {
  .app-init-loading-layer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    font-size: 2vw;

    .app-init-spinner {
      display: block;
      width: 8vw;
      height: 8vw;
      position: relative;
    }

    .app-init-spinner div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border: 1vw solid var(--color--secondary);
      border-radius: 50%;
      animation: init-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: var(--color--secondary) transparent transparent transparent;
    }

    .app-init-spinner div:nth-child(1) {
      animation-delay: -0.45s;
    }

    .app-init-spinner div:nth-child(2) {
      animation-delay: -0.3s;
    }

    .app-init-spinner div:nth-child(3) {
      animation-delay: -0.15s;
    }

    @keyframes init-spinner {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
