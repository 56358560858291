.snackbar-background {
  background: var(--color--icon-bgGray) !important;

  &.success {
    background: var(--color--snackbar-successBackground) !important;
    color: var(--color--snackbar-successText) !important;
  }

  &.error {
    background: var(--color--snackbar-errorBackground) !important;
    color: var(--color--snackbar-errorText) !important;
  }

  &.warning {
    background: var(--color--snackbar-warningBackground) !important;
    color: var(--color--snackbar-warningText) !important;
  }

  &.info {
    background: var(--color--snackbar-infoBackground) !important;
    color: var(--color--snackbar-infoText) !important;
  }
}

.snackbar-container {
  margin-top: calc(var(--size--navbarHeight) + var(--size--spacing-x2)) !important;
}
