.mat-form-field-outline {
  background: white;
}

.mat-snack-bar-container {
  &.success {
    background: #376e37;
    color: white;
  }

  &.error {
    background: #b71c1c;
    color: white;
  }

  &.warning {
    background: #ffbb33;
    color: white;
  }
}
