@use '@angular/material' as mat;

$ocean-5: #f6fbfd;
$ocean-10: #e5f8ff;
$ocean-20: #ccf1ff;
$ocean-30: #aadff2;
$ocean-40: #77ceed;
$ocean-50: #57b6d9;
$ocean-60: #4796b2;
$ocean-70: #336b80;
$ocean-80: #244c5a;
$ocean-90: #142b33;
$ocean-100: #0d1b20;

$grey-10: #f9fcff;
$grey-20: #f0f2f5;
$grey-30: #dfe5eb;
$grey-40: #ccd2d9;
$grey-50: #acb6bf;
$grey-60: #85919e;
$grey-70: #667380;
$grey-80: #3e4c59;
$grey-90: #ffffff;
$grey-100: #1f2933;

$emerald-10: #edfaf7;
$emerald-20: #d9fff7;
$emerald-30: #b6f2e6;
$emerald-40: #8ae5d3;
$emerald-50: #21d9b4;
$emerald-60: #00bf99;
$emerald-70: #00a584;
$emerald-80: #00735c;
$emerald-90: #005443;
$emerald-100: #003329;

$mint-10: #edfaf3;
$mint-20: #d9ffeb;
$mint-30: #b6f2d2;
$mint-40: #9aedc1;
$mint-50: #7adfa9;
$mint-60: #69bf91;
$mint-70: #5ba67e;
$mint-80: #468061;
$mint-90: #315944;
$mint-100: #1c3327;

$forest-10:  #edfaf9;
$forest-20:  #d9fffb;
$forest-30:  #b6f2ec;
$forest-40:  #8ae5dc;
$forest-50:  #21d9c6;
$forest-60:  #00bfac;
$forest-70:  #00a695;
$forest-80:  #006e63;
$forest-90:  #004d45;
$forest-100: #00332e;

$lime-10:  #fbfff2;
$lime-20:  #f6ffde;
$lime-30:  #e7f7be;
$lime-40:  #d9f299;
$lime-50:  #cbe882;
$lime-60:  #b5d474;
$lime-70:  #a2ba66;
$lime-80:  #7a8c4d;
$lime-90:  #4e5931;
$lime-100: #2c331c;

$golden-10:  #fffcf2;
$golden-20:  #fff5d9;
$golden-30:  #ffe7a6;
$golden-40:  #ffdd80;
$golden-50:  #ffc72a;
$golden-60:  #facf57;
$golden-70:  #cc9e21;
$golden-80:  #997718;
$golden-90:  #59450e;
$golden-100: #332808;

$tangerine-10:  #fff8f2;
$tangerine-20:  #ffebd9;
$tangerine-30:  #ffd1a6;
$tangerine-40:  #ffbd80;
$tangerine-50:  #faa657;
$tangerine-60:  #f98e2b;
$tangerine-70:  #cc7523;
$tangerine-80:  #99571a;
$tangerine-90:  #59330f;
$tangerine-100: #331d09;

$primary-color: #4a4a4a;
$secondary-color: #2f6f7a;
$light-gray: #eeeeee;
$gray: #c4c4c4;

$dark-primary-text: $grey-100;
$light-primary-text: white;

$typography: mat.define-typography-config(
  $font-family: 'Source Sans Pro',
  );

$ocean-palette: (
  50 : $ocean-10,
  100: $ocean-20,
  200: $ocean-30,
  300: $ocean-40,
  400: $ocean-50,
  500: $ocean-60,
  600: $ocean-70,
  700: $ocean-80,
  800: $ocean-90,
  900: $ocean-10,
  A100: $grey-20,
  A200: $grey-30,
  A400: $grey-50,
  A700: $grey-80,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $light-primary-text,
  )
);


$emerald-palette: (
  50 : $emerald-10,
  100: $emerald-20,
  200: $emerald-30,
  300: $emerald-40,
  400: $emerald-50,
  500: $emerald-60,
  600: $emerald-70,
  700: $emerald-80,
  800: $emerald-90,
  900: $emerald-100,
  A100: $mint-20,
  A200: $mint-30,
  A400: $mint-50,
  A700: $mint-80,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $light-primary-text,
  )
);


//colors
$error: #dc1e1e;
$success: #55be47;
$warning: #ff9800;

$my-app-primary: mat.define-palette($ocean-palette, 700);
$my-app-accent: mat.define-palette($emerald-palette, 600);
$my-app-warn: mat.define-palette(mat.$red-palette);
$kids-typography: mat.define-typography-config(
  $font-family: "Source Sans Pro"
);


$my-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-app-primary,
      accent: $my-app-accent,
      warn: $my-app-warn,
    ),
    typography: $kids-typography,
  )
);
@include mat.legacy-core();
@include mat.all-legacy-component-typographies($my-app-theme);
@include mat.all-legacy-component-themes($my-app-theme);
@include mat.all-component-themes($my-app-theme);


h1,h2,h3,h4,h5,h6 {
  color: var(--ocean)
}


h1,h2{
  font-family: 'Roboto';
}

* {
  font-family: 'Source Sans Pro';
}

// Buttons
.mdc-button {
  letter-spacing: normal;
}

button.mat-button-base, a.mat-button-base, button.mat-mdc-button-base, a.mat-mdc-button-base {
  border-radius: 24px;
  font-family: "Source Sans Pro";
  font-weight: bold;

  &.mat-raised-button, &.mat-stroked-button, &.mat-mdc-raised-button, &.mat-mdc-outlined-button {
  }

  &.mat-stroked-button, &.mat-mdc-outlined-button {
    border-width: 2px;
  }

  &.mat-stroked-button.mat-primary , &.mat-mdc-outlined-button.mat-primary {
    border-color: var(--ocean-80);
  }

  &.mat-stroked-button.mat-accent , &.mat-mdc-outlined-button.mat-accent {
    border-color: var(--color-accent);
  }

  &.mat-primary:not(.mat-raised-button):not(.mat-mdc-raised-button) {
    color: var(--ocean-80);
  }
}
